import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionIntro from "../components/ui/SectionIntro/SectionIntro";

import aboutPageData from "../content/pages/aboutPage.json";

interface AboutPageProps {}

const AboutPage: React.FC<AboutPageProps> = ({}) => {
  return (
    <Layout>
      <SEO
        title={aboutPageData.seo.seoTitle}
        description={aboutPageData.seo.metaDescription}
        slug="about"
      />

      <section className="bg-gray-800">
        <div className="container">
          <h1 className="text-4xl text-white mb-0 leading-none">
            {aboutPageData.topSection.headline}
          </h1>
        </div>
      </section>

      <section id="about">
        <div className="container">
          <div className="flex flex-wrap lg:flex-row-reverse items-center lg:justify-between">
            <div className="lg:w-6/12 sm:w-8/12 w-full max-w-screen-sm mb-5 lg:mb-0">
              <StaticImage
                src="../../static/images/aboutPage-image.jpg"
                alt="About"
                layout="fullWidth"
              />
            </div>
            <div className="w-full lg:w-5/12 max-w-screen-sm">
              <SectionIntro
                subHeading={aboutPageData.about.subHeadline}
                heading={aboutPageData.about.headline}
              />
              {aboutPageData.about.description.split(/\n/g).map(
                (paragraph, index) =>
                  !!paragraph && (
                    <p key={`about-p-${index}`} className="mb-3 last:mb-0">
                      {paragraph}
                    </p>
                  )
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutPage;
